import Marcius from "../../../Assets/Images/Sponzori/Marcius.png";
import Duplico from "../../../Assets/Images/Sponzori/Duplico.png";
import Dojkic from "../../../Assets/Images/Sponzori/Dojkic.png";
import Decathlon from "../../../Assets/Images/Sponzori/Decathlon.png";
import Karcher from "../../../Assets/Images/Sponzori/Karcher.png";
import Jambrisak from "../../../Assets/Images/Sponzori/Jambrisak.png";
import Hervis from "../../../Assets/Images/Sponzori/Hervis.png";
import Dudoterm from "../../../Assets/Images/Sponzori/Dudoterm.png";
import DomBoje from "../../../Assets/Images/Sponzori/D-om-Boje.png";
import BistroPetarRauch from "../../../Assets/Images/Sponzori/Bisto-Petar-Rauch.png";
import KodBake from "../../../Assets/Images/Sponzori/Kod-Bake.png";
import Zona14 from "../../../Assets/Images/Sponzori/Zona14.png";
import HKZP from "../../../Assets/Images/Sponzori/HKZP.png";
import Germania from "../../../Assets/Images/Sponzori/Germania.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Sponsors() {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((position + 1) % 5);
    }, 6000);

    return () => clearInterval(interval);
  }, [position]);

  const moveLeft = () => {
    setPosition((position - 1 + 6) % 6);
  };

  const moveRight = () => {
    setPosition((position + 1) % 6);
  };

  return (
    <>
      <div className="kit-slider sponsors">
        <button id="left-slide" className="btn-slide" onClick={moveLeft}>
          <i className="fi fi-rs-angle-left"></i>
        </button>
        <div className="slider">
          {position === 0 && (
            <div className="slider-box">
              <Link to="https://www.ak-dojkic.hr/" target="_blank">
                <img
                  className="sponsors-img"
                  src={Dojkic}
                  alt="Dojkic"
                  loading="lazy"
                />
              </Link>
            </div>
          )}
          {position === 1 && (
            <div className="slider-box">
              <Link to="https://duplico.com/hr" target="_blank">
                <img
                  className="sponsors-img"
                  src={Duplico}
                  alt="Duplico"
                  loading="lazy"
                />
              </Link>
            </div>
          )}
          {position === 2 && (
            <div className="slider-box">
              <Link to="https://www.marcius.hr/" target="_blank">
                <img
                  className="sponsors-img"
                  src={Marcius}
                  alt="Marcius"
                  loading="lazy"
                />
              </Link>
            </div>
          )}
          {position === 3 && (
            <div className="slider-box">
              <Link to="https://www.decathlon.hr/" target="_blank">
                <img
                  className="sponsors-img"
                  src={Decathlon}
                  alt="Decathlon"
                  loading="lazy"
                />
              </Link>
            </div>
          )}
          {position === 4 && (
            <div className="slider-box">
              <Link to="https://www.crocontrol.hr/" target="_blank">
                <img
                  src={HKZP}
                  alt="Hrvatska kontrola zračne plovidbe"
                  loading="lazy"
                />
              </Link>
            </div>
          )}
          {position === 5 && (
            <div className="slider-box">
              <Link to="https://zone14.ai/en/" target="_blank">
                <img src={Zona14} alt="Zone 14" loading="lazy" />
              </Link>
            </div>
          )}
        </div>
        <button id="right-slide" className="btn-slide" onClick={moveRight}>
          <i className="fi fi-rs-angle-right"></i>
        </button>
      </div>
      <div className="sponsors-desktop">
        <Link to="https://www.ak-dojkic.hr/" target="_blank">
          {" "}
          <img
            className="sponsors-img"
            src={Dojkic}
            alt="Dojkic"
            target="_blank"
            loading="lazy"
          />
        </Link>
        <Link to="https://duplico.com/hr">
          <img
            className="sponsors-img"
            src={Duplico}
            alt="Duplico"
            target="_blank"
            loading="lazy"
          />
        </Link>
        <Link to="https://www.marcius.hr/">
          <img
            className="sponsors-img"
            src={Marcius}
            alt="Marcius"
            loading="lazy"
          />
        </Link>
        <Link to="https://www.decathlon.hr/">
          <img
            className="sponsors-img"
            src={Decathlon}
            alt="Decathlon"
            loading="lazy"
          />
        </Link>
        <Link to="https://www.crocontrol.hr/">
          <img
            className="sponsors-img"
            src={HKZP}
            alt="Hrvatska kontrola zračne plovidbe"
            loading="lazy"
          />
        </Link>
        <Link to="https://zone14.ai/en/" target="_blank">
          {" "}
          <img
            className="sponsors-img"
            src={Zona14}
            alt="Zone 14"
            target="_blank"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="sponsors-grid">
        <Link
          to="https://www.kaercher.com/hr/"
          target="_blank"
          className="sponsors-link"
        >
          <img src={Karcher} alt="Karcher" loading="lazy" />
        </Link>
        <Link
          to="https://jambrisak-mtm.hr/"
          target="_blank"
          className="sponsors-link"
        >
          <img src={Jambrisak} alt="Jambrisak" loading="lazy" />
        </Link>
        <Link
          to="https://www.hervis.hr/shop/"
          target="_blank"
          className="sponsors-link"
        >
          <img src={Hervis} alt="Hervis" loading="lazy" />
        </Link>
        <Link
          to="https://www.bistro-petarrauch.com/"
          target="_blank"
          className="sponsors-link"
        >
          <img src={BistroPetarRauch} alt="Bistro Petar Rauch" loading="lazy" />
        </Link>

        <Link
          to="https://www.facebook.com/KodBakee/?locale=hr_HR"
          className="sponsors-link"
          target="_blank"
        >
          <img src={KodBake} alt="Bistro Kod Bake" loading="lazy" />
        </Link>
        <Link
          to="https://www.facebook.com/p/D-OM-boje-100054480368968/?_rdr"
          className="sponsors-link"
          target="_blank"
        >
          <img src={DomBoje} alt="D-om Boje" loading="lazy" />
        </Link>
        <Link
          to="https://www.poslovna.hr/lite/dudo-term/1055692/subjekti.aspx"
          className="sponsors-link"
          target="_blank"
        >
          <img src={Dudoterm} alt="Dudo Term" loading="lazy" />
        </Link>
        <Link
          to="https://www.germaniasport.hr/hr?gad_source=1&gclid=CjwKCAiArKW-BhAzEiwAZhWsIGP1uCH-pdwb-wwrcU3jhPVmo3UBIqZ0bfqvwFIAeMgRB6WQU0TuyRoChiUQAvD_BwE#/"
          className="sponsors-link"
          target="_blank"
        >
          <img src={Germania} alt="Germania sport" loading="lazy" />
        </Link>
      </div>
    </>
  );
}
