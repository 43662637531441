import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Components
import Main from "./Main";
import Dresovi from "./Dresovi";
import Matches from "./Matches";
import News from "../News/News";
import Standings from "./Standings";
import MemberHome from "./MemberHome";
import Statistics from "./Statistics";
import MatchesSlider from "./MatchesSlider";

export default function Home() {
  const [matches, setMatches] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "NK Podsused";

    const fetchMatches = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const matchList = data.utakmice || [];

          const uniqueMatches = new Map();
          matchList.forEach((match) => {
            const uniqueKey = `${match.round.trim()}-${match.date}-${
              match.homeTeam
            }-${match.awayTeam}`;
            if (!uniqueMatches.has(uniqueKey)) {
              uniqueMatches.set(uniqueKey, match);
            }
          });

          const sortedMatches = Array.from(uniqueMatches.values()).sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          /* PRIKAZI 1 UTAKMICU SENIORA */
          const latestMatches = sortedMatches.slice(0, 2);
          setMatches(latestMatches);
        } else {
          setMessage("Dokument ne postoji.");
        }
      } catch (error) {
        setMessage("Došlo je do greške prilikom dohvaćanja utakmica.");
      }
    };

    const fetchNewsData = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const newsList = data.novosti || [];
          setNewsData(newsList.map((item, index) => ({ id: index, ...item })));
        } else {
          setMessage("Dokument ne postoji.");
        }
      } catch (error) {
        setMessage("Došlo je do greške prilikom dohvaćanja novosti.");
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
    fetchNewsData();
  }, []);

  const sortedNews = [...newsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const latestNews = sortedNews.slice(0, 2);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />
      <div className="section">
        <div className="season-matches-top">
          <Matches matches={matches} />
          <Link
            to="/utakmice"
            className="btn utakmice-btn"
            onClick={() => window.scrollTo(0, 0)}
          >
            Seniori
          </Link>
        </div>

        <MatchesSlider />
        <Dresovi />
        {message && <div className="error-message">{message}</div>}
        <h2>NOVOSTI</h2>
        <div className="grid-2">
          {loading ? (
            <div>Učitavanje novosti...</div>
          ) : (
            latestNews.map((newsItem) => (
              <News key={newsItem.id} slug={newsItem.slug} />
            ))
          )}
        </div>
        <div className="news-btn">
          <Link
            to="/novosti"
            className="btn"
            onClick={() => window.scrollTo(0, 0)}
          >
            Ostale novosti...
          </Link>
        </div>
        <div className="light-border"></div>
        <h2>SEZONA 2024/2025</h2>
        <h5 className="league-title">2. ZAGREBAČKA LIGA</h5>
        <Standings />
        <div className="light-border"></div>
        <h2>STRIJELCI NK PODSUSED</h2>
        <h2>SENIORI</h2>
        <h5 className="league-title">SEZONA 2024/2025</h5>
        <Statistics />
        <div className="light-border"></div>
        <MemberHome />
      </div>
    </>
  );
}
