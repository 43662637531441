import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export default function MatchesSlider() {
  const [matches, setMatches] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const ostaleUtakmice = data.ostaleUtakmice || [];

          const categoryOrder = [
            "Veterani",
            "Seniori",
            "Kadeti 1.ZNL",
            "Kadeti 2.ZNL",
            "Pioniri 1.ZNL",
            "Pioniri 3.ZNL",
            "Mlađi Pioniri",
            "Limači",
            "Zagići",
          ];

          const allMatches = categoryOrder
            .map((category) => {
              return {
                category,
                matches: ostaleUtakmice
                  .filter((match) => match.category === category)
                  .sort((a, b) => new Date(b.date) - new Date(a.date)),
              };
            })
            .filter((item) => item.matches.length > 0);

          setMatches(allMatches);
        } else {
          setMessage("Dokument ne postoji.");
        }
      } catch (error) {
        setMessage("Došlo je do greške prilikom dohvaćanja utakmica.");
      }
    };
    fetchMatches();
  }, []);

  const nextMatch = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % matches.length);
  };

  const prevMatch = () => {
    setCurrentIndex((prevIndex) => {
      if (matches.length === 0) return 0; // If no matches, return to the first index
      return prevIndex === 0 ? matches.length - 1 : prevIndex - 1; // If at index 0, go to the last index
    });
  };
  const formatDate = (dateString) => {
    if (!dateString) return "Nepoznat datum"; // Fallback ako je undefined/null
    if (dateString.includes(".")) {
      return dateString;
    }
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="matches-slider">
      <h2>OSTALE UTAKMICE</h2>
      {message && <div className="error-message">{message}</div>}
      {matches.length > 0 && (
        <div className="match-info-container">
          <button className="slider-btn left" onClick={prevMatch}>
            <i className="fi fi-rs-angle-left"></i>
          </button>
          <div className="match-info">
            <h3>{matches[currentIndex].category}</h3>
            <h2>{matches[currentIndex].matches[0].round}</h2>
            <div className="match-result">
              <h3>{matches[currentIndex].matches[0].homeTeam}</h3>
              <h2>{matches[currentIndex].matches[0].result}</h2>
              <h3>{matches[currentIndex].matches[0].awayTeam}</h3>
            </div>
            <h5>
              <i className="fi fi-rs-calendar-days"></i>
              {formatDate(matches[currentIndex].matches[0].date)} -{" "}
              {matches[currentIndex].matches[0].time}
            </h5>

            <h5>
              {" "}
              <i className="fi fi-rs-marker"></i>
              {matches[currentIndex].matches[0].location}
            </h5>
          </div>
          <button className="slider-btn right" onClick={nextMatch}>
            <i className="fi fi-rs-angle-right"></i>
          </button>
        </div>
      )}
      {matches.length > 0 && (
        <div className="slider-indicators">
          {matches.map((_, index) => (
            <span
              key={index}
              className={`indicator ${
                index === currentIndex ? "active-match" : ""
              }`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
}
