import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";
import YellowCard from "../../../Assets/Images/Ostalo/YellowCard.png";
import RedCard from "../../../Assets/Images/Ostalo/RedCard.png";
import Postave from "../../../Assets/Images/Ostalo/Postave.png";
import Player from "../../../Assets/Images/Ostalo/Player.png";

export default function Matches({ matches }) {
  const [logos, setLogos] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const clubLogos = {};
          data.klubovi.forEach((club) => {
            clubLogos[club.klub] = club.logoUrl;
          });
          setLogos(clubLogos);
        } else {
          setError("Dokument ne postoji.");
        }
      } catch (error) {
        setError("Došlo je do greške prilikom dohvata podataka.");
      } finally {
        setLoading(false);
      }
    };

    fetchLogos();
  }, []);

  const toggleDetails = (matchId) => {
    setShowDetails((prev) => ({
      ...prev,
      [matchId]: !prev[matchId],
    }));
  };

  if (loading) {
    return <div className="center">Učitavanje...</div>;
  }
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      {matches.map((match, index) => (
        <div
          key={match.id || `${match.round}-${index}`}
          id={`match-${match.round}`}
          className="match-info"
        >
          <h2>{match.round}</h2>
          <div className="match-result">
            <div className="slide-left team-info">
              {logos[match.homeTeam] && (
                <img
                  className="match-team-logo"
                  src={logos[match.homeTeam]}
                  alt={match.homeTeam}
                  loading="lazy"
                />
              )}
              <h3 className="team-name">{match.homeTeam}</h3>
            </div>
            <div className="final-result">
              <h2>{match.result}</h2>
            </div>
            <div className="slide-right team-info">
              {logos[match.awayTeam] && (
                <img
                  className="match-team-logo"
                  src={logos[match.awayTeam]}
                  alt={match.awayTeam}
                  loading="lazy"
                />
              )}
              <h3 className="team-name">{match.awayTeam}</h3>
            </div>
          </div>
          <h5>
            <i className="fi fi-rs-calendar-days"></i>
            {formatDate(match.date)} - {match.time}
          </h5>
          <h5>
            <i className="fi fi-rs-marker"></i>
            {match.location}
          </h5>
          <div className="match-details">
            {showDetails[match.id] && (
              <>
                {match.events && match.events.length > 0 && (
                  <Details details={match.events} />
                )}
                {match.lineup && match.lineup.length > 0 && (
                  <Lineups
                    players={match.lineup}
                    benchPlayers={match.benchPlayers}
                  />
                )}
              </>
            )}
          </div>
          {(match.events?.length > 0 || match.lineup?.length > 0) && (
            <button
              className="details-btn"
              onClick={() => toggleDetails(match.id)}
            >
              {showDetails[match.id] ? "Prikaži manje" : "Detalji sa utakmice"}
            </button>
          )}
        </div>
      ))}
    </>
  );
}

function Details(props) {
  const renderEventImage = (eventType) => {
    switch (eventType) {
      case "Gol":
        return <img src={Ball} alt="Goal" loading="lazy" />;
      case "Žuti karton":
        return <img src={YellowCard} alt="Yellow Card" loading="lazy" />;
      case "Crveni karton":
        return <img src={RedCard} alt="Red Card" loading="lazy" />;
      default:
        return null;
    }
  };

  return (
    <ul>
      {props.details.map((detail, index) => (
        <li className="details" key={index}>
          {detail.team === "Domaći" && (
            <span className="left">
              {detail.player} {renderEventImage(detail.type)} {detail.time}’
            </span>
          )}
          {detail.team === "Gostujući" && (
            <span className="right">
              {detail.time}’ {renderEventImage(detail.type)} {detail.player}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
}

function Lineups(props) {
  return (
    <div className="lineups">
      <div className="lineups-stadium">
        <img src={Postave} alt="Teren" loading="lazy" />
        <div className="players">
          {props.players &&
            props.players.length > 0 &&
            props.players.map((player, index) => (
              <div key={index} className={`player ${player.position}`}>
                <img
                  src={Player}
                  alt={player.player}
                  className="player-img"
                  loading="lazy"
                />
                <span>{player.player}</span>
              </div>
            ))}
        </div>
      </div>
      <div className="bench-players">
        {props.benchPlayers &&
          props.benchPlayers.length > 0 &&
          props.benchPlayers.map((player, index) => (
            <div key={index} className={`player ${player.pos}`}>
              <img
                src={Player}
                alt={player.name}
                className="player-img"
                loading="lazy"
              />
              <h6>{player.name}</h6>
            </div>
          ))}
      </div>
    </div>
  );
}
